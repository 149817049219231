<template>
    <div>
        <div class="callout-pull-quote">
            <div class="grid-container">
                <div class="grid-x grid-padding-x">
                    <div class="small-12 cell">
                        <h1><font-awesome-icon class="quote-left" icon="quote-left" /></h1>
                        <h2><em>Physiatrists treat acute and chronic pain and musculoskeletal disorders.</em></h2>         
                    </div>
                </div>
            </div>
        </div>
        <div class="grid-container">
            <div class="grid-x grid-margin-x">
                <div class="small-12 cell">
                    <h1>What is a Physiatrist?</h1>
                    <p>A physiatrist (fizz ee at' trist) is a physician specializing in physical medicine and rehabilitation. Physiatrists treat a wide range of problems from sore shoulders to spinal cord injuries. They see patients in all age groups and treat problems that touch upon all the major systems in the body. These specialists focus on restoring function to people.</p>

                    <p>To become a physiatrist, individuals must successfully complete four years of graduate medical education and four additional years of postdoctoral residency training. Residency training includes one year spent developing fundamental clinical skills and three additional years of training in the full scope of the specialty.</p>

                    <p>There are 79 accredited residency programs in physical medicine and rehabilitation in the United States. Many physiatrists choose to pursue additional advanced degrees (MS, PhD) or complete fellowship training in a specific area of the specialty. Fellowships are available for specialized study in such areas as musculoskeletal rehabilitation, pediatrics, traumatic brain injury, spinal cord injury, and sports medicine.</p>

                    <p>To become board-certified in physical medicine and rehabilitation, physiatrists are required to pass both a written and oral examination administered by the American Board of Physical Medicine and Rehabilitation (ABPM&R). The ABPM&R also has agreements with each of the boards of pediatrics, internal medicine, and neurology to allow special training programs leading to certification in both specialties.</p>

                    <p>Physiatrists treat acute and chronic pain and musculoskeletal disorders. They may see a person who lifts a heavy object at work and experiences back pain, a basketball player who sprains an ankle and needs rehabilitation to play again, or a knitter who has carpal tunnel syndrome. Physiatrists' patients include people with arthritis, tendonitis, any kind of back pain, and work- or sports-related injuries.</p>

                    <p>Physiatrists also treat serious disorders of the musculoskeletal system that result in severe functional limitations. They would treat a baby with a birth defect, someone in a bad car accident, or an elderly person with a broken hip. Physiatrists coordinate the long-term rehabilitation process for patients with spinal cord injuries, cancer, stroke or other neurological disorders, brain injuries, amputations, and multiple sclerosis.</p>

                    <p>Physiatrists practice in rehabilitation centers, hospitals, and in private offices. They often have broad practices, but some concentrate on one area such as pediatrics, sports medicine, geriatric medicine, brain injury, or many other special interests.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
export default {
    components: {
        FontAwesomeIcon,
    }
}
</script>

<style lang="scss" scoped>

</style>