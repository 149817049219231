import Vue from "vue";
import VueRouter from 'vue-router';
import App from "./App.vue";
import $ from "jquery";
import Foundation from "foundation-sites";
import store from "./store.js";
//Install fontawesome, popper.js & lodash
//npm i --save @fortawesome/fontawesome-free @fortawesome/fontawesome-svg-core @fortawesome/free-solid-svg-icons @fortawesome/vue-fontawesome popper.js lodash
import {library} from "@fortawesome/fontawesome-svg-core";
import {faTimes, faBars, faArrowsAltH, faArrowsAlt, faPrint, faArrowLeft, faArrowRight, faMapMarkerAlt, faCaretRight, faAngleRight, faSignInAlt, faFile, faQuoteLeft, faQuoteRight, faFilePdf, faExclamationCircle, faDownload} from "@fortawesome/free-solid-svg-icons";
library.add(faTimes, faBars, faArrowsAltH, faArrowsAlt, faPrint, faArrowLeft, faArrowRight, faMapMarkerAlt, faCaretRight, faAngleRight, faSignInAlt, faFile, faQuoteLeft, faQuoteRight, faFilePdf, faExclamationCircle, faDownload);

import HomePage from "./components/HomePage"
import PatientInfo from "./components/PatientInfo"
import Bio from "./components/Bio"
import Publications from "./components/Publications"
import Awards from "./components/Awards"
import WhatIsAPhysiatrist from "./components/WhatIsAPhysiatrist"
import WhatIsAnEMG from "./components/WhatIsAnEMG"
import WhatAreTriggerPointInjections from "./components/WhatAreTriggerPointInjections"
import HIPAA from "./components/HIPAA"
import FindUs from "./components/FindUs"
import Terms from "./components/Terms"
import Disclaimer from "./components/Disclaimer"
import NotFoundComponent from "./components/NotFoundComponent"

Vue.use(VueRouter);
Vue.config.productionTip = false;

const routes = [
  { path: "/", component: HomePage },
  { path:"/PatientInfo", component: PatientInfo },
  { path:"/Bio", component: Bio },
  { path:"/Publications", component: Publications },
  { path:"/Awards", component: Awards },
  { path:"/WhatIsAPhysiatrist", component: WhatIsAPhysiatrist },
  { path:"/WhatIsAnEMG", component: WhatIsAnEMG },
  { path:"/WhatAreTriggerPointInjections", component: WhatAreTriggerPointInjections },
  { path:"/HIPAA", component: HIPAA },
  { path:"/FindUs", component: FindUs },
  { path: "/Terms", component: Terms},
  { path: "/Disclaimer", component: Disclaimer},
  { path: '*', component: NotFoundComponent }
]

const router = new VueRouter({ routes, mode: 'history', scrollBehavior (to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition
  } else {
    return { x: 0, y: 0 }
  }
}});

//Vue directive control of foundation equalizer
//TODO: Add an Foundation.MediaQuery.current and _reInit()
Vue.directive('f-equalizer', {
  bind: function (el, binding) {
    Vue.nextTick(function () {
      el.equalizer = new Foundation.Equalizer($(el), binding.value);
    });
  },
  inserted: function (el, binding) {
    Vue.nextTick(function () {
      el.equalizer = new Foundation.Equalizer($(el), binding.value);
    });
  },
  update: function (el, binding) {
    Vue.nextTick(function () {
      el.equalizer = new Foundation.Equalizer($(el), binding.value);
    });
  },
  unbind: function (el) {
    $(el).foundation('_destroy');
    el.equalizer = null;
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

