<template>
    <div>
        <div class="callout-pull-quote">
            <div class="grid-container">
                <div class="grid-x grid-padding-x">
                    <div class="small-12 cell">
                        <h1><font-awesome-icon class="quote-left" icon="quote-left" /></h1>
                        <h2><em>(TPI) is a procedure used to treat painful areas of muscle that contain trigger points, or knots of muscle that form when muscles fail to relax.</em></h2>         
                    </div>
                </div>
            </div>
        </div>
        <div class="grid-container">
            <div class="grid-x grid-margin-x">
                <div class="small-12 cell">
                    <h1>What are Trigger Point Injections?</h1>
                    <p>Trigger point injection (TPI) is a procedure used to treat painful areas of muscle that contain trigger points, or knots of muscle that form when muscles fail to relax. The knot can often be felt under the skin and may twitch involuntarily when touched (called a jump sign). The most common places that have spasm and large knots (trigger points) are the trapezii muscles of the shoulders, the rhomboids between the shoulder blades, and the low back. Trigger point injections can be done anywhere there is a muscle.</p>

                    <p>The trigger point can cause referred pain — pain felt in another part of the body. Increased pain, loss of range of motion, and weakness may develop over time.</p>

                    <p>During the procedure, a small needle is inserted into the trigger point and lidocaine or marcaine is injected into the muscle trigger point, which helps to relieve muscle spasm. The injections are followed by massage to the area, either by a physical therapist or massage therapist.</p>

                    <p>The injections are done once a week for two weeks, if helping, they can be continued for up to a total of six weeks. If two sessions do not help, discontinue the injections.</p>

                    <p>Scheduling the injections to coordinate with physical therapy is often the most difficult step. Copays and deductibles apply for all visits. Please schedule the physical therapy first, as the therapist needs more time for the hands-on treatment, then contact us to schedule the injections.</p>
                    <h1>Risks</h1>
                    <p>Problems are uncommon. Rarely, there can be bleeding or infection from any shot. A nerve can be injured by shots in some areas. Allergic reaction to the medication may occur. Trigger points over the chest have a small chance of causing a pneumothorax, a condition where air gets outside of the lung and needs to be removed by placing a chest tube (a tube inside the chest but outside of the lung) for a day or two. As with any procedure, the chance of injury (risk) needs to be less than the chance of improvement. If the risk of injury is more than the likelihood of improvement, there would be no reason to do the procedure.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
export default {
    components: {
        FontAwesomeIcon,
    }
}
</script>

<style lang="scss" scoped>

</style>