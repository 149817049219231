<template>
    <div>
        <div class="primary-background">
            <div class="grid-container">
                <div class="grid-x grid-padding-x">
                    <div class="small-12 medium-4 cell text-center medium-text-left">
                        <img v-bind:src="require(`../assets/dr-leppard.png`)" />         
                    </div>
                    <div class="medium-8 cell align-self-middle hide-for-small-only">
                        <h1>Dr. Katharine Leppard, M.D., P.T.</h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid-container">
            <div class="grid-x grid-margin-x grid-padding-y">
                <div class="small-12 cell">
                    <h1 class="show-for-small-only">Dr. Katharine Leppard, M.D., P.T.</h1>
                    <p class="lead">Dr. Leppard received her undergraduate degrees in Physical Therapy and Biology at Oakland University in Rochester Michigan.</p>
                    <p>She worked as a Physical Therapist before attending Medical School at Wayne State University in Detroit Michigan, graduating in 1992. She completed a one year Internal Medicine Transitional Program at Beaumont Hospital in Royal Oak Michigan. Her Physical Medicine and Rehabilitation residency was at the University of Michigan Hospital in Ann Arbor Michigan, where she was chief resident her senior year.</p>

                    <p>Moving to Colorado Springs in 1996, she joined Pikes Peak Physical Medicine, also working her first year as a staff physician on the Rehabilitation Unit at Penrose Hospital. After 11 ½ years at Pikes Peak Physical Medicine, she founded Medical Rehabilitation Specialists in 2007.</p>

                    <h2>Dr. Leppard is board certified in:</h2>
                    <ul>
                        <li>Physical Medicine and Rehabilitation</li>
                        <li>Electrodiagnostic Medicine</li>
                        <li>Neuromuscular Medicine</li>
                        <li>Pain Medicine</li>
                    </ul>

                    <p>She has been active at the local and state level, serving on several committees including Colorado Medical Society Committee of Workers' Compensation, physician reviewer for the Colorado Board of Medical Examiners, task force member for the Division of Workers' Compensation.</p>

                    <p>Honors include America’s Top Physicians; Alpha Omega Alpha Medical Honor Society and Biltmore Women Who’s Who. She is a member of the American Association of Neuromuscular and Electrodiagnostic Medicine, the Academy of Physical Medicine and Rehabilitation.</p>
                    <div class="grid-x grid-margin-x">
                        <div class="small-12 medium-6 cell">
                            <router-link to="/Publications" class="button secondary large expanded">View Publications <font-awesome-icon class="arrow-right" icon="arrow-right" /></router-link>
                        </div>
                        <div class="small-12 medium-6 cell">
                            <router-link to="/Awards" class="button secondary large expanded">View Awards and Articles <font-awesome-icon class="arrow-right" icon="arrow-right" /></router-link>
                        </div>
                    </div>

                    
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
export default {
    components: {
        FontAwesomeIcon,
    }
}
</script>

<style lang="scss" scoped>

</style>