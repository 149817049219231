<template>
    <div>
        <div class="callout-pull-quote">
            <div class="grid-container">
                <div class="grid-x grid-padding-x">
                    <div class="small-12 cell">
                        <h1>Awards and Articles</h1>   
                        <p class="lead">The below publications are in Adobe PDF format. If you do not have Adobe Reader you can download it.</p> 
                        <a href="https://get.adobe.com/reader/" target="_blank" class="button secondary large">Adobe Acrobat Reader <font-awesome-icon class="download" icon="download" /></a> 
                    </div>
                </div>
            </div>
        </div>
        <div class="grid-container">
            <div class="grid-x grid-margin-x grid-padding-y">
                <div class="small-12 cell" v-f-equalizer="{ equalizeByRow: true }">

                    <h2>Awards and Articles About Dr. Leppard</h2>
                    <div class="grid-x grid-margin-x">
                        <div class="small-12 large-6 cell callout" v-for="pa in pubAbout" v-bind:key="pa.id">
                            <div class="grid-x grid-margin-x">
                                <div class="small-12 medium-shrink cell">
                                    <a v-bind:href="pa.article_url" target="blank"><img v-bind:src="require(`../assets/${pa.thumb}`)"/></a>
                                </div>
                                <div class="small-12 medium-auto cell align-self-middle">
                                    <h4>{{pa.title}}</h4>
                                    <a v-bind:href="pa.article_url" target="blank" class="button primary small">Read publication</a>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
    created() {
        this.$store.dispatch("fetchPubAbout");
        console.log(this.pubAbout);
    },
    components: {
        FontAwesomeIcon,
    },
    computed: {
        ...mapState(["pubAbout"])
    }
}
</script>

<style lang="scss" scoped>
    @import '../_settings.scss';
    img {
        border: 1px solid $light-gray;
        /* shadow with blur */
        filter: drop-shadow(2px 2px 2px $light-gray) 
    }
    .callout {
        border: 1px solid $light-gray;
        border-bottom: 3px solid $medium-gray;   
    }
</style>