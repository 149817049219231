<template>
    <div>
        <div class="grid-container full">
            <div class="grid-x">
                <div class="small-12 cell text-center callout-pull-quote" v-bind:style="{backgroundImage: 'url(' + setImg() + ')'}">
                    
                    <img v-bind:src="require(`../assets/logo-white.png`)" class="logo-padding" />
                    <h2>Medical Rehabilitation Specialists</h2>
                    <h3>Dr. Katharine Leppard, M.D., P.T.</h3>
                    
                    <div class="hero-spacing show-for-large"></div>
                    <div class="grid-x grid-margin-x">
                        <div class="small-12 medium-6 large-3 large-offset-3 cell">
                            <a href="https://medical-rehab.myezyaccess.com/" target="_blank" class="button expanded large">Patient Portal Login <font-awesome-icon class="sign-in-alt" icon="sign-in-alt" /></a>
                        </div>
                        <div class="small-12 medium-6 large-3 cell">
                            <a href="https://medical-rehab.com/pdf/new%20patient%20forms.pdf" target="_blank" class="button expanded large secondary"><font-awesome-icon class="file" icon="file" /> New Patient Forms</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid-container">
            <div class="grid-x grid-margin-x grid-padding-y">
                <div class="small-12 cell">
                    <h1>Welcome</h1>
                    <p>
                        Dr. Leppard's emphasis is diagnosing and treating musculoskeletal problems and peripheral (arm and leg) nerve injuries and illness. She began her care of patients as a physical therapist in 1986. With a special interest in Electrodiagnostic Medicine - EMG nerve studies, she strives to help patients with diagnostic challenges. She coordinates care with other health care professionals, and offers nonsurgical approaches to injuries. Dr. Leppard has had the privilege of practicing medicine in Colorado Springs since 1996.
                    </p>
                    <h2>We specialize in</h2>
                    <ul>
                        <li>Electrodiagnostic Medicine EMG Studies</li>
                        <li>Non-Operative Musculoskeletal Medicine</li>
                        <li>Non-Surgical Spine Treatment Options</li>
                    </ul>
                    <h2>Conditions we diagnose and treat</h2>
                    <ul>
                        <li>Carpal tunnel syndrome</li>
                        <li>Cubital Tunnel syndrome</li>
                        <li>Sciatica</li>
                        <li>Peripheral Neuropathy</li>
                        <li>Cervical radiculopathy</li>
                        <li>Lumbar radiculopathy</li>
                        <li>Back and neck pain</li>
                    </ul>
                    <router-link to="/Bio" class="button secondary large">Learn more about Dr. Leppard <font-awesome-icon class="arrow-right" icon="arrow-right" /></router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from "jquery"
import Foundation from "foundation-sites"
import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
export default {
    components: {
        FontAwesomeIcon,
    },
    data() {
        return {
            currentSize: '',
            oldSize: ''
        }
    },
    mounted() {
        const vm = this;
        this.$nextTick(() => {
            Foundation.MediaQuery = MediaQuery;
            Foundation.MediaQuery._init();

            //Couldn't get Foundation.Interchange() to work as a directive
            //Creating my own version
            //TODO: Convert this to a vue component
            vm.currentSize = Foundation.MediaQuery.current;
            $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
                vm.currentSize = newSize;
                vm.oldSize = oldSize;
            });
        });       
    },
    methods: {
        setImg() {
            var img = require(`../assets/medical-rehabilitation-specialists-large.png`);
            console.log(this.currentSize);
            switch(this.currentSize) {
                case "small":
                    img =  require(`../assets/medical-rehabilitation-specialists-small.png`);
                    break;
                case "medium":
                    img =  require(`../assets/medical-rehabilitation-specialists-medium.png`);
                    break;
                case "large":
                    img =  require(`../assets/medical-rehabilitation-specialists-large.png`);
                    break;
                case "xlarge":
                    img = require(`../assets/medical-rehabilitation-specialists-xlarge.png`);
                    break;
                case "xxlarge":
                    img =  require(`../assets/medical-rehabilitation-specialists-xxlarge.png`);
                    break;
                case "xxxlarge":
                    img =  require(`../assets/medical-rehabilitation-specialists-xxxlarge.png`);
                    break;
                default:
            }
            return img;
        }
    }
}
</script>

<style lang="scss" scoped>
    .hero-spacing {
        min-height: 6em;
    }
</style>