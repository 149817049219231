<template>
    <div>
        <div class="callout-pull-quote">
            <div class="grid-container">
                <div class="grid-x grid-padding-x">
                    <div class="small-12 cell text-center">
                        <h1><font-awesome-icon class="exclamation-circle" icon="exclamation-circle" /></h1>
                        <h1>404 Not Found.</h1>        
                    </div>
                </div>
            </div>
        </div>
        <div class="grid-container">
            <div class="grid-x grid-margin-x">
                <div class="small-12 cell text-center" style="min-height:500px; padding-top:3em;">
                <p class="lead">The page you were looking for was not found or does not exsist.</p>
                <router-link to="/">Return to our home page</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
export default {
    components: {
        FontAwesomeIcon,
    }
}
</script>

<style lang="scss" scoped>

</style>