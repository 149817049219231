<template>
    <div>
        <div class="callout-pull-quote">
            <div class="grid-container">
                <div class="grid-x grid-padding-x">
                    <div class="small-12 cell">
                        <h1>New Patient Information</h1>     
                        <a href="https://medical-rehab.com/pdf/new%20patient%20forms.pdf" target="_blank" class="button secondary large"><font-awesome-icon class="file" icon="file" /> New Patient Forms</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid-container">
            <div class="grid-x grid-margin-x grid-padding-y">
                <div class="small-12 cell">
                    <p class="lead">Medical Rehabilitation Specialists is pleased to see patients on a number of different insurance plans. If you are interested in becoming a new patient, appointments can be scheduled by calling 719-575-1800.</p>

                    <p>Some insurance companies require a referral from a primary care physician; it is the patient’s responsibility to ensure that the referral is obtained before each appointment. Co-pays are due the day of service and deductibles apply.</p>

                    <p>We realize that as a patient you may have questions before or after your clinic visit or procedure. Our goal is to provide you with the highest level of care and service. Before beginning treatment we will discuss your case with you in detail and try to answer all of your questions.</p>

                    <ul>
                        <li>Please bring with you any test results including x-rays, bone scans, or MRIs pertaining to your injury.</li>
                        <li>For arm exams: please wear or bring a sleeveless shirt or tank top for the exam.</li>
                        <li>For hip, knee, leg, ankle, or foot exams: Please wear or bring a pair of shorts.</li>
                    </ul>
                    
                </div>
                <div style="min-height:600px; padding-top:3em;"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
export default {
    components: {
        FontAwesomeIcon,
    }
}
</script>

<style lang="scss" scoped>

</style>