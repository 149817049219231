<template>
    <div>
        <div class="callout-pull-quote">
            <div class="grid-container">
                <div class="grid-x grid-padding-x">
                    <div class="small-12 cell">
                        <h1><font-awesome-icon class="quote-left" icon="quote-left" /></h1>
                        <h2><em>Electrodiagnostic Medicine is the application of special techniques used to help diagnose conditions that cause numbness, tingling, pain, weakness, fatigue, and muscle cramping.</em></h2>         
                    </div>
                </div>
            </div>
        </div>
        <div class="grid-container">
            <div class="grid-x grid-margin-x">
                <div class="small-12 cell">
                    <h1>What is an EMG?</h1>
                    <p>Electrodiagnostic Medicine is the application of special techniques used to help diagnose conditions that cause numbness, tingling, pain, weakness, fatigue, and muscle cramping. Two types of electrodiagnostic tests are used to study nerve and muscle function, Nerve Conduction Studies (NCS) and Electromyography (EMG). The doctor who performs and analyzes these tests can tell if your nerves or muscles are not working properly.</p>

                    <p>The first part of the EMG is a nerve conduction study. Electrodes are placed on your skin over a nerve that your doctor wants to study. A small electrical impulse is passed through one electrode. An instrument measures the resulting electrical activity, such as the time it takes for the impulse to travel to another electrode. In some nerve disorders, the speed of the signal is dramatically slowed. This test may be used to diagnose peripheral nerve disorders, such as carpal tunnel syndrome.</p>

                    <p>Electromyography (EMG) (e-LEC-tro-my-OG-ruf-ee) measures the electrical activity of muscles both at rest and contracted (flexed). It is performed when the doctor suspects a problem with your muscles or the nerves that control them. A thin needle electrode is inserted into the muscle your doctor wants to study. An instrument records the electrical activity in your muscle at rest and as you contract the muscle, such as by bending your arm. The size, duration and frequency of these signals helps determine if there's damage to the muscle or to the nerves leading to the muscle.</p>

                    <p>Most common problems that are evaluated by these tests include: pinched nerves in the neck or low back, carpal, syndrome, and other nerve injuries in the arms or legs. Other medical conditions that may involve problems with nerves and muscles include: peripheral neuropathies (nerve disorders), myopathies (muscle disorders), and other neurologic disorders. Whether your doctor think they may have a simple problem, or possibly a more serious medical condition, electrodiagnostic testing can be an important part of finding out what is wrong, and what to do about it.</p>

                    <p>Each test is tailored to find out precisely what the problem is and provide the answer that your doctor is looking for.</p>

                    <p>Electrodiagnostic tests involve no radiation or medication, and are safer than most tests. It is normal to experience a mild muscle ache for a few days after the test, and patients will occasionally notice a small bruise, which disappears. There are no long-term side effects.</p>

                    <p>Each test may take anywhere from 20 minutes to an hour or slightly longer, depending upon your specific problem(s), exactly what the doctor is looking for and how much testing is needed to get the necessary information. It is uncommon that a test will last much longer than an hour.</p>

                    <p>If you have any serious medical problems, you should tell your doctor before any testing is performed. You should take all of your regular medication, including pain medications, before the test unless your doctor tells you otherwise or unless you have a neurologic condition called myasthenia gravis. In this case, you should discuss this with the doctor who prescribed the medication you're taking before having any testing performed. Your physician should also be informed if you have a cardiac pacemaker.</p>

                    <p>There are no restrictions on activity or eating before or after the tests. There are no lasting after-effects, and the nerves and muscles being tested will not be damaged by the testing. In the case of any progressive pain, swelling, or other undesirable symptoms that develop and worsen after the test is completed, you should contact the doctor who performed the test to discuss your situation in more detail.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
export default {
    components: {
        FontAwesomeIcon,
    }
}
</script>

<style lang="scss" scoped>

</style>