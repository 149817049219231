import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
//import _ from "lodash";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        pubWritten: [],
        pubAbout: []
    },
    mutations: {
        SET_PUBWRITTEN(state, payload) {
            state.pubWritten = payload;
        },
        SET_PUBABOUT(state, payload) {
            state.pubAbout = payload;
        },
    },
    actions: {
        fetchPubWritten({commit}) {
            axios
            .get("./pubwritten.json") //local data feed example
            .then(response => (commit("SET_PUBWRITTEN", response.data)))
            .catch(error => console.log(error));
        },
        fetchPubAbout({commit}) {
            axios
            .get("./pubabout.json") //local data feed example
            .then(response => (commit("SET_PUBABOUT", response.data)))
            .catch(error => console.log(error));
        },
    }
});