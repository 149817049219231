<template>
    <div>
        <div class="grid-container full">
            <div class="grid-x">
                <div class="small-12 cell">
                    <img class="primary-border-top-bottom full" v-bind:src="setImg()" width="100%"/>
                </div>
            </div>
        </div>
        <div class="grid-container" style="margin-top: 1em;">
            <div class="grid-x grid-margin-x grid-padding-y">
                <div class="small-12 medium-6 cell callout">
                    <h3>Location</h3>
                    <p>
                        Centennial Professional Building<br>
                        3470 Centennial Blvd.<br>
                        Suite 110<br>
                        Colorado Springs, CO 80907
                    </p>
                    <p><strong>Hours</strong><br>
                        Monday through Thursday: 9:00am to 4:00pm<br>
                        Friday: 9:00 amd to Noon<br>
                    </p>
                    <p>Closed Staturday, Sunday and Holidays</p>
                    <a href="https://www.google.com/maps/dir//Medical+Rehabilitation+Specialists,+3470+Centennial+Blvd,+Colorado+Springs,+CO+80907/@38.8809246,-104.883213,13z/data=!4m9!4m8!1m0!1m5!1m1!1s0x8713501faacf5edd:0x2e9960b47b7e6a47!2m2!1d-104.8482087!2d38.8807709!3e0" target="_blank" class="button secondary large"><font-awesome-icon class="map-marker-alt" icon="map-marker-alt" /> Get Directions</a>
                </div>
                <div class="small-12 medium-6 cell callout">
                    <h3>Contact Information</h3>
                    <h2>(719) 575-1800 <small>Phone Number</small><br>
                        (719) 575-1850 <small>Fax Number</small></h2>

                    <p>*If you have a medical issue, please do not email, call us instead</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from "jquery"
import Foundation from "foundation-sites"
import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
export default {
    components: {
        FontAwesomeIcon,
    },
    data() {
        return {
            currentSize: '',
            oldSize: ''
        }
    },
    mounted() {
        const vm = this;
        this.$nextTick(() => {
            Foundation.MediaQuery = MediaQuery;
            Foundation.MediaQuery._init();

            //Couldn't get Foundation.Interchange() to work as a directive
            //Creating my own version
            //TODO: Convert this to a vue component
            vm.currentSize = Foundation.MediaQuery.current;
            $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
                vm.currentSize = newSize;
                vm.oldSize = oldSize;
            });
        });       
    },
    methods: {
        setImg() {
            var img = require(`../assets/map-location-large.png`);
            console.log(this.currentSize);
            switch(this.currentSize) {
                case "small":
                    img =  require(`../assets/map-location-small.png`);
                    break;
                case "medium":
                    img =  require(`../assets/map-location-medium.png`);
                    break;
                case "large":
                    img =  require(`../assets/map-location-large.png`);
                    break;
                case "xlarge":
                    img = require(`../assets/map-location-xlarge.png`);
                    break;
                case "xxlarge":
                    img =  require(`../assets/map-location-xlarge.png`);
                    break;
                case "xxxlarge":
                    img =  require(`../assets/map-location-xlarge.png`);
                    break;
                default:
            }
            return img;
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '../_settings.scss';
    .callout {
        border: 1px solid $light-gray;
        border-bottom: 3px solid $medium-gray;   
    }
</style>