<template>
    <div>
        <div class="callout-pull-quote">
            <div class="grid-container">
                <div class="grid-x grid-padding-x">
                    <div class="small-12 cell">
                        <h1>HIPPA Notice</h1>
                        <p class="lead">Notice of Privacy Practices for Protected Health Information (“Notice”)<br>
                        <small>Effective 4/14/2003</small></p>

                        <a href="https://medical-rehab.com/pdf/Notice%20of%20Privacy%20Practices%20for%20Protected%20Health%20Info.pdf" target="_blank" class="button secondary large"><font-awesome-icon class="file-pdf" icon="file-pdf" /> Download PDF</a>         
                    </div>
                </div>
            </div>
        </div>
    <div class="grid-container">
        <div class="grid-x grid-margin-x grid-padding-y">
            <div class="small-12 cell">
                <p><strong>This notice describes how medical information about you may be used and disclosed and how you can get access to this information. Please review it carefully!</strong> Medical Rehabilitation Specialists is permitted by federal privacy laws to maintain the privacy of your protected health information. This information consists of all records related to your health, including demographic information, either created by Medical Rehabilitation Specialists or received by Medical Rehabilitation Specialists from other healthcare providers.</p>

                <p>We are required to provide you with notice of our legal duties and privacy practices with respect to your protected health information. These legal duties an privacy practices are described in this Notice. Medical Rehabilitation Specialists will abide by the terms of this Notice.</p>

                <p>Medical Rehabilitation Specialists reserves the right to change the terms of this Notice and to make any new provisions effective for all projected health information that we maintain. Patients will be provided a copy of any revised Notice upon request. An individual may obtain a copy of the current Notice from our office at any time.</p>

                <p><strong>Uses and Disclosures of Your Protected Health Information Not Requiring Consent:</strong><br>
                Medical Rehabilitation Specialists is permitted by federal privacy laws to make uses and disclosures of your health information for purposes of treatment, payment and healthcare operations. Protected health information is the information we create and obtain in providing our services to you. Such information may include documenting your symptoms, examination, and test results, diagnoses, treatment and applying for future care or treatment. It also includes billing documents for those services. There are certain restrictions on uses and disclosures of treatment records which include registration and all other records concerning individuals who are receiving or who at any time have received services for mental illness developmental disabilities, alcoholism or drug dependence. There are also restrictions on disclosing HIV test results.</p>

                <p><strong>Examples of Uses of Your Health Information for Treatment Purposes are:</strong></p>
                <ul>
                    <li>The physician communicates information about his / her treatment with you to the primary care physician who referred you to Medical Rehabilitation Specialists.</li>
                    <li>A medical assistant obtains information about you prior to scheduling an MRI or injection and records it in a health record.</li>
                    <li>During the course of your treatment, the physician determines he/she will need to consult with another specialist in the area. He/she will share the information with such specialist and obtain his/her input.</li>
                </ul>
                
                <p><strong>Use of Your Health Information for Payment Purposes:</strong></p>
                <ul>
                    <li>Activities undertaken by Medical Rehabilitation Specialists to obtain reimbursement for services provided to you</li>
                    <li>Determining your eligibility for benefits or health insurance coverage</li>
                    <li>Managing claims and contacting your insurance company regarding payment</li>
                    <li>Collection activities to obtain payment for services provide to you</li>
                    <li>Reviewing healthcare services and discussing with your insurance company the medical necessity of certain services or procedures, coverage under your plan, appropriateness of care, or justification of charges</li>
                    <li>Obtaining precertification and preauthorization of services provided to you</li>
                </ul>

                <p>For example, Medical Rehabilitation Specialists will submit claims to your insurance company on your behalf. This claim identifies you, your diagnosis and the services provided to you.</p>

                <p><strong>Use of Your Information for Healthcare Operations:</strong><br>
                We obtain services from our insurers or other business associates such as quality assessment, quality improvement, outcome evaluation, protocol and clinical guideline development, training programs, credentialing, medical review, legal services, and insurance. We will share information about you with such insurers or other business associates as necessary to obtain these services.</p>

                <p>Medical Rehabilitation Specialists may contact you, by telephone or mail, to provide appointment reminders. You must notify us if you do not wish to receive appointment reminders.</p>

                <p><strong>We may not disclose your protected health information to family members or friends who may be involved with your treatment or care without your written permission. Health information may be released without written permission to a parent, guardian or legal custodian of a child; the guardian of an incompetent adult; the healthcare agent designated in an incapacitated patient’s healthcare power of attorney; or the personal representative or spouse of a deceased patient.</strong></p>

                <p class="lead">Your Health Information Rights</p>

                <p><strong>The health and billing records we maintain are the physical property of Medical Rehabilitation Specialists. The information in it, however, belongs to you. You have a right to:</strong></p>

                <ul>
                <li>Request a restriction on certain uses and disclosures of your health information by delivering the request to our office -- we are not required to grant the request, but we will comply with any request granted;</li>
                <li>Obtain a paper copy of the current Notice of Privacy Practices for Protected Health Information (“Notice”) by making a request at our office;</li>
                <li>Request that you be allowed to inspect and copy your health record and billing record – you may exercise this right by delivering the request to our office;</li>
                <li>Appeal a denial of access to your protected health information, except in certain circumstances;</li>
                <li>Request that your health care record be amended to correct incomplete or incorrect information by delivering a request to our office. We may deny your request if you ask us to amend information that:
                    <ul>
                        <li>Was not created by us, unless the person or entity that created the information is no longer available to make the amendment;</li>
                        <li>Is not part of the health information kept by or for the office;</li>
                        <li>Is not part of the information that you would be permitted to inspect and copy; or,</li>
                        <li>Is accurate and complete.</li>
                    </ul>
                </li>
                </ul>
                <p>If your request is denied, you will be informed of the reason for the denial and will have an opportunity to submit a statement of disagreement to be maintained with your records;</p>
                
                <ul>
                    <li>Request that communication of your health information be made by alternative means or at an alternative location by delivering the request in writing to our office;</li>
                    <li>Obtain an accounting of disclosures of your health information as required to be maintained by law by delivering a request to our office. An accounting will not include uses and disclosures of information for treatment, payment, or operations; disclosures or uses made to you or made at your request; uses or disclosures made pursuant to an authorization signed by you; uses or disclosures made in a facility directory or to family members or friends relevant to that person's involvement in your care or in payment for such care; or, uses or disclosures to notify family or others responsible for your care of your location, condition, or your death.</li>
                    <li>Revoke authorizations that you made previously to use or disclose information by delivering a written revocation to our office, except to the extent information or action has already been taken.</li>
                </ul>
                <p>If you want to exercise any of the above rights, please contact us in person or in writing, during regular, business hours. We will inform you of the steps that need to be taken to exercise your rights.</p>

                <p class="lead">Our Responsibilities</p>

                <p><strong>The office is required to:</strong></p>

                <ul>
                    <li>Maintain the privacy of your health information as required by law;</li>
                    <li>Provide you with a notice as to our duties and privacy practices as to the information we collect and maintain about you;</li>
                    <li>Abide by the terms of this Notice;</li>
                    <li>Notify you if we cannot accommodate a requested restriction or request; and,</li>
                    <li>Accommodate your reasonable requests regarding methods to communicate health information with you.</li>
                </ul>

                <p>We reserve the right to amend, change, or eliminate provisions in our privacy practices and access practices and to enact new provisions regarding the protected health information we maintain. If our information practices change, we will amend our Notice. You are entitled to receive a revised copy of the Notice by calling and requesting a copy of our "Notice" or by visiting our office and picking up a copy.</p>

                <p class="lead">To Request Information or File a Complaint</p>

                <p>If you have questions, would like additional information, or want to report a problem regarding the handling of your information, you may contact us at 3470 Centennial Blvd, Ste 110, Colorado Springs, CO 80907 or 719.575.1800.</p>

                <p>Additionally, if you believe your privacy rights have been violated, you may file a written complaint at our office by delivering the written complaint to us. You may also file a complaint by mailing it or emailing it to the Secretary of Health and Human Services, whose street address is: Office for Civil Rights - U.S. Department of Health and Human Services - 200 Independence Avenue S.W. – Room 509F, HHH Building- Washington, D.C. 20201, visit their web site at http://www.hhs.gov/ocr/hipaa or call the HIPAA toll-free number at (866) 627-7748.</p>

                <ul>
                    <li>We cannot, and will not, require you to waive the right to file a complaint with the Secretary of Health and Human Services (HHS) as a condition of receiving treatment from the office/hospital.</li>
                    <li>We cannot, and will not, retaliate against you for filing a complaint with the Secretary of Health and Human Services.</li>
                </ul>

                <p class="lead">Other Disclosures and Uses</p>

                <p><strong>Communication with Family</strong> -- Using our best judgment, we may disclose to a family member, other relative, close personal friend, or any other person you identify, health information relevant to that person's involvement in your care or in payment for such care if you do not object or in an emergency.</p>

                <p><strong>Notification</strong> -- Unless you object, we may use or disclose your protected health information to notify, or assist in notifying, a family member, personal representative, or other person responsible for your care, about your location, and about your general condition, or your death.</p>

                <p><strong>Research</strong> -- We may disclose information to researchers when their research has been approved by an institutional review board that has reviewed the research proposal and established protocols to ensure the privacy of your protected health information.</p>

                <p><strong>Disaster Relief</strong> -- We may use and disclose your protected health information to assist in disaster relief efforts.</p>

                <p><strong>Organ Procurement Organizations</strong> -- Consistent with applicable law, we may disclose your protected health information to organ procurement organizations or other entities engaged in the procurement, banking, or transplantation of organs for the purpose of tissue donation and transplant.</p>

                <p><strong>Food and Drug Administration (FDA)</strong> -- We may disclose to the FDA your protected health information relating to adverse events with respect to food, supplements, products and product defects, or post-marketing surveillance information to enable product recalls, repairs, or replacements.</p>

                <p><strong>Workers’ Compensation</strong> -- If you are seeking compensation through Workers’ Compensation, we may disclose your protected health information to the extent necessary to comply with laws relating to Workers’ Compensation.</p>

                <p><strong>Public Health</strong> -- As authorized by law, we may disclose your protected health information to public health or legal authorities charged with preventing or controlling disease, injury, or disability; to report reactions to medications or problems with products; to notify people of recalls; to notify a person who may have been exposed to a disease or who is at risk for contracting or spreading a disease or condition.</p>

                <p><strong>Abuse & Neglect</strong> -- We may disclose your protected health information to public authorities as allowed by law to report abuse or neglect.</p>

                <p><strong>Employers</strong> -- We may release health information about you to your employer if we provide health care services to you at the request of your employer, and the health care services are provided either to conduct an evaluation relating to medical surveillance of the workplace or to evaluate whether you have a work-related illness or injury. Any other disclosures to your employer will be made only if you execute a specific authorization for the release of that information to your employer.</p>

                <p><strong>Correctional Institutions</strong> -- If you are an inmate of a correctional institution, we may disclose to the institution or its agents the protected health information necessary for your health and the health and safety of other individuals.</p>

                <p><strong>Law Enforcement</strong> -- We may disclose your protected health information for law enforcement purposes as required by law, such as when required by a court order, or in cases involving felony prosecution, or to the extent an individual is in the custody of law enforcement.</p>

                <p><strong>Health Oversight</strong> -- Federal law allows us to release your protected health information to appropriate health oversight agencies or for health oversight activities.</p>

                <p><strong>Judicial/Administrative Proceedings</strong> -- We may disclose your protected health information in the course of any judicial or administrative proceeding as allowed or required by law, with your authorization, or as directed by a proper court order.</p>

                <p><strong>Serious Threat</strong> -- To avert a serious threat to health or safety, we may disclose your protected health information consistent with applicable law to prevent or lessen a serious, imminent threat to the health or safety of a person or the public.</p>

                <p><strong>For Specialized Governmental Functions</strong> -- We may disclose your protected health information for specialized government functions as authorized by law such as to Armed Forces personnel, for national security purposes, or to public assistance program personnel.</p>

                <p><strong>Coroners, Medical Examiners, and Funeral Directors</strong> -- We may release health information to a coroner or medical examiner. This may be necessary, for example, to identify a deceased person or determine the cause of death. We may also release health information about patients to funeral directors as necessary for them to carry out their duties.</p>

                <p><strong>Other Uses</strong> -- Other uses and disclosures, besides those identified in this Notice, will be made only as otherwise required by law or with your written authorization and you may revoke the authorization as previously provided in this Notice under "Your Health Information Rights."</p>

                <p>If we maintain a website that provides information about our entity, this Notice will be on the website.</p>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
export default {
    components: {
        FontAwesomeIcon,
    }
}
</script>

<style lang="scss" scoped>

</style>