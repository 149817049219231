<template>
    <div class="grid-container">
        <div class="grid-x grid-margin-x">
            <div class="small-12 cell">
                <h1>Terms</h1>
                <!-- TERMS --->

    <p class="lead">Medical Rehabilitation Specialists - Information on Terms of Service and Use</p>

<strong>ACCEPTANCE OF TERMS.</strong>
<P>The services that Medical Rehabilitation Specialists provides to you are subject to the following 
Terms of Use ("TOU"). Medical Rehabilitation Specialists reserves the right to update the TOU at any time 
without notice to you. The most current version of the TOU can be reviewed by 
clicking on the "Terms of Use" hypertext link located at the bottom of our Web 
pages. </P>
    <DIV style="MARGIN-TOP: 3px; MARGIN-BOTTOM: 10px"></DIV>
    <strong>DESCRIPTION OF SERVICES.</strong>
<P>Through its network of Web properties, Medical Rehabilitation Specialists provides you with access to 
a variety of resources, including developer tools, download areas, communication 
forums and product information (collectively "Services"). The Services, 
including any updates, enhancements, new features, and/or the addition of any 
new Web properties, are subject to the TOU. </P>
    <DIV style="MARGIN-TOP: 3px; MARGIN-BOTTOM: 10px"></DIV>
    <strong>PERSONAL AND NON-COMMERCIAL USE LIMITATION.</strong>
<P>Unless otherwise specified, the Services are for your personal and 
non-commercial use. You may not modify, copy, distribute, transmit, display, 
perform, reproduce, publish, license, create derivative works from, transfer, or 
sell any information, software, products or services obtained from the Services. 
</P>
    <DIV style="MARGIN-TOP: 3px; MARGIN-BOTTOM: 10px"></DIV>
    <strong>PRIVACY AND PROTECTION OF PERSONAL INFORMATION.</strong>
    <P>We do not sell or share any information submitted on this web site. </P>
    <DIV style="MARGIN-TOP: 3px; MARGIN-BOTTOM: 10px"></DIV>
    <strong>NOTICE SPECIFIC TO SOFTWARE AVAILABLE ON THIS WEB SITE.</strong>
<P>Any software that is made available to download from the Services 
("Software") is the copyrighted work of Medical Rehabilitation Specialists and/or its suppliers. Use of 
the Software is governed by the terms of the end user license agreement, if any, 
which accompanies or is included with the Software ("License Agreement"). An end 
user will be unable to install any Software that is accompanied by or includes a 
License Agreement, unless he or she first agrees to the License Agreement 
terms.</P>
<P>The Software is made available for download solely for use by end users 
according to the License Agreement. Any reproduction or redistribution of the 
Software not in accordance with the License Agreement is expressly prohibited by 
law, and may result in severe civil and criminal penalties. Violators will be 
prosecuted to the maximum extent possible.</P>
<P>WITHOUT LIMITING THE FOREGOING, COPYING OR REPRODUCTION OF THE SOFTWARE TO 
ANY OTHER SERVER OR LOCATION FOR FURTHER REPRODUCTION OR REDISTRIBUTION IS 
EXPRESSLY PROHIBITED, UNLESS SUCH REPRODUCTION OR REDISTRIBUTION IS EXPRESSLY 
PERMITTED BY THE LICENSE AGREEMENT ACCOMPANYING SUCH SOFTWARE. </P>
    <P>THE SOFTWARE IS WARRANTED, IF AT ALL, ONLY ACCORDING TO THE TERMS OF THE 
      LICENSE AGREEMENT. EXCEPT AS WARRANTED IN THE LICENSE AGREEMENT, Medical Rehabilitation Specialists HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH REGARD 
      TO THE SOFTWARE, INCLUDING ALL WARRANTIES AND CONDITIONS OF MERCHANTABILITY, 
      WHETHER EXPRESS, IMPLIED OR STATUTORY, FITNESS FOR A PARTICULAR PURPOSE, 
      TITLE AND NON-INFRINGEMENT.</P>
<P>FOR YOUR CONVENIENCE, Medical Rehabilitation Specialists MAY MAKE AVAILABLE AS PART OF THE SERVICES OR 
IN ITS SOFTWARE PRODUCTS, TOOLS AND UTILITIES FOR USE AND/OR DOWNLOAD. Medical Rehabilitation Specialists 
DOES NOT MAKE ANY ASSURANCES WITH REGARD TO THE ACCURACY OF THE RESULTS OR 
OUTPUT THAT DERIVES FROM SUCH USE OF ANY SUCH TOOLS AND UTILITIES. PLEASE 
RESPECT THE INTELLECTUAL PROPERTY RIGHTS OF OTHERS WHEN USING THE TOOLS AND 
UTILITIES MADE AVAILABLE ON THE SERVICES OR IN Medical Rehabilitation Specialists SOFTWARE PRODUCTS.</P>
    <P>RESTRICTED RIGHTS LEGEND. Any Software which is downloaded from the Services 
      for or on behalf of the United States of America, its agencies and/or instrumentalities 
      ("U.S. Government"), is provided with Restricted Rights. Use, duplication, 
      or disclosure by the U.S. Government is subject to restrictions as set forth 
      in subparagraph (c)(1)(ii) of the Rights in Technical Data and Computer 
      Software clause at DFARS 252.227-7013 or subparagraphs (c)(1) and (2) of 
      the Commercial Computer Software - Restricted Rights at 48 CFR 52.227-19, 
      as applicable. Manufacturer is Medical Rehabilitation Specialists Corporation.</P>
    <P></P>
<strong>NOTICE SPECIFIC TO DOCUMENTS AVAILABLE ON THIS WEB SITE.</strong>
<P>Permission to use Documents (such as white papers, press releases, datasheets 
and FAQs) from the Services is granted, provided that (1) the below copyright 
notice appears in all copies and that both the copyright notice and this 
permission notice appear, (2) use of such Documents from the Services is for 
informational and non-commercial or personal use only and will not be copied or 
posted on any network computer or broadcast in any media, and (3) no 
modifications of any Documents are made. Accredited educational institutions, 
such as K-12, universities, private/public colleges, and state community 
colleges, may download and reproduce the Documents for distribution in the 
classroom. Distribution outside the classroom requires express written 
permission. Use for any other purpose is expressly prohibited by law, and may 
result in severe civil and criminal penalties. Violators will be prosecuted to 
the maximum extent possible.</P>
<P>Documents specified above do not include the design or layout of the 
Medical Rehabilitation Specialists.com Web site or any other Medical Rehabilitation Specialists owned, operated, licensed or 
controlled site. Elements of Medical Rehabilitation Specialists Web sites are protected by trade dress, 
trademark, unfair competition, and other laws and may not be copied or imitated 
in whole or in part. No logo, graphic, sound or image from any Medical Rehabilitation Specialists Web 
site may be copied or retransmitted unless expressly permitted by Medical Rehabilitation Specialists.</P>
<P>Medical Rehabilitation Specialists AND/OR ITS RESPECTIVE SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE 
SUITABILITY OF THE INFORMATION CONTAINED IN THE DOCUMENTS AND RELATED GRAPHICS 
PUBLISHED AS PART OF THE SERVICES FOR ANY PURPOSE. ALL SUCH DOCUMENTS AND 
RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND. Medical Rehabilitation Specialists 
AND/OR ITS RESPECTIVE SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS 
WITH REGARD TO THIS INFORMATION, INCLUDING ALL WARRANTIES AND CONDITIONS OF 
MERCHANTABILITY, WHETHER EXPRESS, IMPLIED OR STATUTORY, FITNESS FOR A PARTICULAR 
PURPOSE, TITLE AND NON-INFRINGEMENT. IN NO EVENT SHALL Medical Rehabilitation Specialists AND/OR ITS 
RESPECTIVE SUPPLIERS BE LIABLE FOR ANY SPECIAL, INDIRECT OR CONSEQUENTIAL 
DAMAGES OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS, 
WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING 
OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF INFORMATION AVAILABLE 
FROM THE SERVICES.</P>
<P>THE DOCUMENTS AND RELATED GRAPHICS PUBLISHED ON THE SERVICES COULD INCLUDE 
TECHNICAL INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED 
TO THE INFORMATION HEREIN. Medical Rehabilitation Specialists AND/OR ITS RESPECTIVE SUPPLIERS MAY MAKE 
IMPROVEMENTS AND/OR CHANGES IN THE PRODUCT(S) AND/OR THE PROGRAM(S) DESCRIBED 
HEREIN AT ANY TIME.</P>
    <DIV style="MARGIN-TOP: 3px; MARGIN-BOTTOM: 10px"></DIV>
    <strong>NOTICES REGARDING SOFTWARE, DOCUMENTS AND SERVICES AVAILABLE ON THIS WEB 
SITE.</strong>
<P>IN NO EVENT SHALL Medical Rehabilitation Specialists AND/OR ITS RESPECTIVE SUPPLIERS BE LIABLE FOR ANY 
SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER RESULTING 
FROM LOSS OF USE, DATA OR PROFITS, WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE 
OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR 
PERFORMANCE OF SOFTWARE, DOCUMENTS, PROVISION OF OR FAILURE TO PROVIDE SERVICES, 
OR INFORMATION AVAILABLE FROM THE SERVICES.</P>
    <DIV style="MARGIN-TOP: 3px; MARGIN-BOTTOM: 10px"></DIV>
    <strong>MEMBER ACCOUNT, PASSWORD, AND SECURITY.</strong>
<P>If any of the Services requires you to open an account, you must complete the 
registration process by providing us with current, complete and accurate 
information as prompted by the applicable registration form. You also will 
choose a password and a user name. You are entirely responsible for maintaining 
the confidentiality of your password and account. Furthermore, you are entirely 
responsible for any and all activities that occur under your account. You agree 
to notify Medical Rehabilitation Specialists immediately of any unauthorized use of your account or any 
other breach of security. Medical Rehabilitation Specialists will not be liable for any loss that you may 
incur as a result of someone else using your password or account, either with or 
without your knowledge. However, you could be held liable for losses incurred by 
Medical Rehabilitation Specialists or another party due to someone else using your account or password. 
You may not use anyone else's account at any time, without the permission of the 
account holder.</P>
    <DIV style="MARGIN-TOP: 3px; MARGIN-BOTTOM: 10px"></DIV>
    <strong>NO UNLAWFUL OR PROHIBITED USE.</strong>
<P>As a condition of your use of the Services, you will not use the Services for 
any purpose that is unlawful or prohibited by these terms, conditions, and 
notices. You may not use the Services in any manner that could damage, disable, 
overburden, or impair any Medical Rehabilitation Specialists server, or the network(s) connected to any 
Medical Rehabilitation Specialists server, or interfere with any other party's use and enjoyment of any 
Services. You may not attempt to gain unauthorized access to any Services, other 
accounts, computer systems or networks connected to any Medical Rehabilitation Specialists server or to 
any of the Services, through hacking, password mining or any other means. You 
may not obtain or attempt to obtain any materials or information through any 
means not intentionally made available through the Services.</P>
    <DIV style="MARGIN-TOP: 3px; MARGIN-BOTTOM: 10px"></DIV>
    <strong>USE OF SERVICES</strong>
<P>The Services may contain e-mail services, bulletin board services, chat 
areas, news groups, forums, communities, personal web pages, calendars, photo 
albums, file cabinets and/or other message or communication facilities designed 
to enable you to communicate with others (each a "Communication Service" and 
collectively "Communication Services"). You agree to use the Communication 
Services only to post, send and receive messages and material that are proper 
and, when applicable, related to the particular Communication Service. By way of 
example, and not as a limitation, you agree that when using the Communication 
Services, you will not:</P>
    <TABLE cellSpacing=2 cellPadding=2 border=0>
      <TBODY>
        <TR> 
          <TD class=listBullet vAlign=top><blockquote> 
              <p>•</p>
            </blockquote></TD>
          <TD class=listItem> <P>Use the Communication Services in connection 
              with surveys, contests, pyramid schemes, chain letters, junk email, 
              spamming or any duplicative or unsolicited messages (commercial 
              or otherwise).</P></TD>
        </TR>
        <TR> 
          <TD class=listBullet vAlign=top><blockquote> 
              <p>•</p>
            </blockquote></TD>
          <TD class=listItem> <P>Defame, abuse, harass, stalk, threaten or otherwise 
              violate the legal rights (such as rights of privacy and publicity) 
              of others.</P></TD>
        </TR>
        <TR> 
          <TD class=listBullet vAlign=top><blockquote> 
              <p>•</p>
            </blockquote></TD>
          <TD class=listItem> <P>Publish, post, upload, distribute or disseminate 
              any inappropriate, profane, defamatory, obscene, indecent or unlawful 
              topic, name, material or information.</P></TD>
        </TR>
        <TR> 
          <TD class=listBullet vAlign=top><blockquote> 
              <p>•</p>
            </blockquote></TD>
          <TD class=listItem> <P>Upload, or otherwise make available, files that 
              contain images, photographs, software or other material protected 
              by intellectual property laws, including, by way of example, and 
              not as limitation, copyright or trademark laws (or by rights of 
              privacy or publicity) unless you own or control the rights thereto 
              or have received all necessary consent to do the same.</P></TD>
        </TR>
        <TR> 
          <TD class=listBullet vAlign=top><blockquote> 
              <p>•</p>
            </blockquote></TD>
          <TD class=listItem> <P>Use any material or information, including images 
              or photographs, which are made available through the Services in 
              any manner that infringes any copyright, trademark, patent, trade 
              secret, or other proprietary right of any party.</P></TD>
        </TR>
        <TR> 
          <TD class=listBullet vAlign=top><blockquote> 
              <p>•</p>
            </blockquote></TD>
          <TD class=listItem> <P>Upload files that contain viruses, Trojan horses, 
              worms, time bombs, cancelbots, corrupted files, or any other similar 
              software or programs that may damage the operation of another's 
              computer or property of another.</P></TD>
        </TR>
        <TR> 
          <TD class=listBullet vAlign=top><blockquote>
              <p>•</p>
            </blockquote></TD>
          <TD class=listItem> <P>Download any file posted by another user of a 
              Communication Service that you know, or reasonably should know, 
              cannot be legally reproduced, displayed, performed, and/or distributed 
              in such manner.</P></TD>
        </TR>
        <TR> 
          <TD class=listBullet vAlign=top><blockquote>
              <p>•</p>
            </blockquote></TD>
          <TD class=listItem> <P>Falsify or delete any copyright management information, 
              such as author attributions, legal or other proper notices or proprietary 
              designations or labels of the origin or source of software or other 
              material contained in a file that is uploaded.</P></TD>
        </TR>
        <TR> 
          <TD class=listBullet vAlign=top><blockquote>
              <p>•</p>
            </blockquote></TD>
          <TD class=listItem> <P>Restrict or inhibit any other user from using 
              and enjoying the Communication Services.</P></TD>
        </TR>
        <TR> 
          <TD class=listBullet vAlign=top><blockquote>
              <p>•</p>
            </blockquote></TD>
          <TD class=listItem> <P>Violate any code of conduct or other guidelines 
              which may be applicable for any particular Communication Service.</P></TD>
        </TR>
        <TR> 
          <TD class=listBullet vAlign=top><blockquote>
              <p>•</p>
            </blockquote></TD>
          <TD class=listItem> <P>Harvest or otherwise collect information about 
              others, including e-mail addresses.</P></TD>
        </TR>
        <TR> 
          <TD class=listBullet vAlign=top><blockquote>
              <p>•</p>
            </blockquote></TD>
          <TD class=listItem> <P>Violate any applicable laws or regulations.</P></TD>
        </TR>
        <TR> 
          <TD class=listBullet vAlign=top><blockquote>
              <p>•</p>
            </blockquote></TD>
          <TD class=listItem> <P>Create a false identity for the purpose of misleading 
              others.</P></TD>
        </TR>
        <TR> 
          <TD class=listBullet vAlign=top><blockquote>
              <p>•</p>
            </blockquote></TD>
          <TD class=listItem> <P>Use, download or otherwise copy, or provide (whether 
              or not for a fee) to a person or entity any directory of users of 
              the Services or other user or usage information or any portion thereof.</P></TD>
        </TR>
      </TBODY>
    </TABLE>
    <P><strong>Medical Rehabilitation Specialists has no obligation to monitor the Communication 
      Services</strong>. However, Medical Rehabilitation Specialists reserves the right 
      to review materials posted to the Communication Services and to remove any 
      materials in its sole discretion. Medical Rehabilitation Specialists reserves 
      the right to terminate your access to any or all of the Communication Services 
      at any time, without notice, for any reason whatsoever.</P>
<P>Medical Rehabilitation Specialists reserves the right at all times to disclose any information as 
Medical Rehabilitation Specialists deems necessary to satisfy any applicable law, regulation, legal 
process or governmental request, or to edit, refuse to post or to remove any 
information or materials, in whole or in part, in Medical Rehabilitation Specialists's sole 
discretion.</P>
<P>Always use caution when giving out any personally identifiable information 
about yourself or your children in any Communication Services. Medical Rehabilitation Specialists does 
not control or endorse the content, messages or information found in any 
Communication Services and, therefore, Medical Rehabilitation Specialists specifically disclaims any 
liability with regard to the Communication Services and any actions resulting 
from your participation in any Communication Services. Managers and hosts are 
not authorized Medical Rehabilitation Specialists spokespersons, and their views do not necessarily 
reflect those of Medical Rehabilitation Specialists.</P>
<P>Materials uploaded to the Communication Services may be subject to posted 
limitations on usage, reproduction and/or dissemination; you are responsible for 
adhering to such limitations if you download the materials.</P>
    <DIV style="MARGIN-TOP: 3px; MARGIN-BOTTOM: 10px"></DIV>
    <strong>MATERIALS PROVIDED TO Medical Rehabilitation Specialists OR POSTED AT ANY Medical Rehabilitation Specialists WEB SITE.</strong>
    <P><strong>Medical Rehabilitation Specialists does not claim ownership</strong> 
      of the materials you provide to Medical Rehabilitation Specialists (including 
      feedback and suggestions) or post, upload, input or submit to any Services 
      or its associated services for review by the general public, or by the members 
      of any public or private community, (each a "Submission" and collectively 
      "Submissions"). However, by posting, uploading, inputting, providing or 
      submitting ("Posting") your Submission you are granting Medical Rehabilitation Specialists, its affiliated companies and necessary sublicensees permission to use 
      your Submission in connection with the operation of their Internet businesses 
      (including, without limitation, all Medical Rehabilitation Specialists Services), 
      including, without limitation, the license rights to: copy, distribute, 
      transmit, publicly display, publicly perform, reproduce, edit, translate 
      and reformat your Submission; to publish your name in connection with your 
      Submission; and the right to sublicense such rights to any supplier of the 
      Services.</P>
<P>No compensation will be paid with respect to the use of your Submission, as 
provided herein. Medical Rehabilitation Specialists is under no obligation to post or use any Submission 
you may provide and Medical Rehabilitation Specialists may remove any Submission at any time in its sole 
discretion.</P>
<P>By Posting a Submission you warrant and represent that you own or otherwise 
control all of the rights to your Submission as described in these Terms of Use 
including, without limitation, all the rights necessary for you to provide, 
post, upload, input or submit the Submissions.</P>
<P>In addition to the warranty and representation set forth above, by Posting a 
Submission that contain images, photographs, pictures or that are otherwise 
graphical in whole or in part ("Images"), you warrant and represent that (a) you 
are the copyright owner of such Images, or that the copyright owner of such 
Images has granted you permission to use such Images or any content and/or 
images contained in such Images consistent with the manner and purpose of your 
use and as otherwise permitted by these Terms of Use and the Services, (b) you 
have the rights necessary to grant the licenses and sublicenses described in 
these Terms of Use, and (c) that each person depicted in such Images, if any, 
has provided consent to the use of the Images as set forth in these Terms of 
Use, including, by way of example, and not as a limitation, the distribution, 
public display and reproduction of such Images. By Posting Images, you are 
granting (a) to all members of your private community (for each such Images 
available to members of such private community), and/or (b) to the general 
public (for each such Images available anywhere on the Services, other than a 
private community), permission to use your Images in connection with the use, as 
permitted by these Terms of Use, of any of the Services, (including, by way of 
example, and not as a limitation, making prints and gift items which include 
such Images), and including, without limitation, a non-exclusive, world-wide, 
royalty-free license to: copy, distribute, transmit, publicly display, publicly 
perform, reproduce, edit, translate and reformat your Images without having your 
name attached to such Images, and the right to sublicense such rights to any 
supplier of the Services. The licenses granted in the preceding sentences for a 
Images will terminate at the time you completely remove such Images from the 
Services, provided that, such termination shall not affect any licenses granted 
in connection with such Images prior to the time you completely remove such 
Images. No compensation will be paid with respect to the use of your Images.</P>
    <DIV style="MARGIN-TOP: 3px; MARGIN-BOTTOM: 10px"></DIV>
    <strong>LINKS TO THIRD PARTY SITES.</strong>
    <P>THE LINKS IN THIS AREA WILL LET YOU LEAVE Medical Rehabilitation Specialists's SITE. 
      THE LINKED SITES ARE NOT UNDER THE CONTROL OF Medical Rehabilitation Specialists 
      AND Medical Rehabilitation Specialists IS NOT RESPONSIBLE FOR THE CONTENTS OF 
      ANY LINKED SITE OR ANY LINK CONTAINED IN A LINKED SITE, OR ANY CHANGES OR 
      UPDATES TO SUCH SITES. Medical Rehabilitation Specialists IS NOT RESPONSIBLE FOR 
      WEBCASTING OR ANY OTHER FORM OF TRANSMISSION RECEIVED FROM ANY LINKED SITE. 
      Medical Rehabilitation Specialists IS PROVIDING THESE LINKS TO YOU ONLY AS A CONVENIENCE, 
      AND THE INCLUSION OF ANY LINK DOES NOT IMPLY ENDORSEMENT BY Medical Rehabilitation Specialists OF THE SITE.</P>
    <DIV style="MARGIN-TOP: 3px; MARGIN-BOTTOM: 10px"></DIV>
    <strong>UNSOLICITED IDEA SUBMISSION POLICY.</strong>
    <P>Medical Rehabilitation Specialists OR ANY OF ITS EMPLOYEES DO NOT ACCEPT OR CONSIDER 
      UNSOLICITED IDEAS, INCLUDING IDEAS FOR NEW ADVERTISING CAMPAIGNS, NEW PROMOTIONS, 
      NEW PRODUCTS OR TECHNOLOGIES, PROCESSES, MATERIALS, MARKETING PLANS OR NEW 
      PRODUCT NAMES. PLEASE DO NOT SEND ANY ORIGINAL CREATIVE ARTWORK, SAMPLES, 
      DEMOS, OR OTHER WORKS. THE SOLE PURPOSE OF THIS POLICY IS TO AVOID POTENTIAL 
      MISUNDERSTANDINGS OR DISPUTES WHEN Medical Rehabilitation Specialists's PRODUCTS 
      OR MARKETING STRATEGIES MIGHT SEEM SIMILAR TO IDEAS SUBMITTED TO Medical Rehabilitation Specialists. SO, PLEASE DO NOT SEND YOUR UNSOLICITED IDEAS TO Medical Rehabilitation Specialists OR ANYONE AT Medical Rehabilitation Specialists. IF, DESPITE OUR 
      REQUEST THAT YOU NOT SEND US YOUR IDEAS AND MATERIALS, YOU STILL SEND THEM, 
      PLEASE UNDERSTAND THAT Medical Rehabilitation Specialists MAKES NO ASSURANCES 
      THAT YOUR IDEAS AND MATERIALS WILL BE TREATED AS CONFIDENTIAL OR PROPRIETARY.</P>
    <DIV style="MARGIN-TOP: 3px; MARGIN-BOTTOM: 10px"></DIV>
    <strong>COPYRIGHT NOTICE &amp; FAQ.</strong>
    <P>© 2007 Medical Rehabilitation Specialists. All rights reserved.</P>
<P>The following is provided for informational purposes only and should not be 
construed as legal advice. If you need legal advice, contact a lawyer.</P>
<P></P>
    <B>Copyright?</B> 
    <P></P>
<P>Copyright law protects original works, such as websites, books, music, 
paintings, photos and video. A work is “original” if it contains some elements 
you created and did not borrow from others. Typically, when you create an 
original work, you own the copyright. As the copyright owner, you can control 
how others use your work. For example, if you write a movie script, you have the 
right to, and can prevent others from, copying your script, sharing it with 
others (“distributing it”), making a movie or book from your script (a 
“derivative work”), or publicly performing your script as a play or movie. You 
also have the ability to sell or give away these rights. In other words, you 
could sell the right to make a movie based on your script to a movie studio.</P>
<P>If you use someone else’s copyrighted materials without permission, that use 
generally violates the copyright owner's exclusive rights, and is copyright 
infringement. So if you create a new work and include parts of other people’s 
works in it (such as an existing photo, lengthy quotes from a book or a loop 
from a song), you must own or have permission to use the elements you borrow. 
For example, if your script is based on an existing popular series, you should 
obtain permission to use the elements you borrow from the series.</P>
<P>Copyright law is different from the law of personal property. If you buy a 
physical object, such as a movie on DVD, you own the physical object. You do 
not, however, obtain ownership of the “copyrights” (the rights to make copies, 
distribute, make derivatives and publicly perform or display) in the content of 
the movie. The fact that you have obtained physical possession of a DVD does not 
automatically grant you the right to copy or share it.</P>
<P>If you make your own movie, it may include many copyrighted works in it. So, 
if you decide to make a movie based on your script, you must either create all 
elements of it on your own, or have permission to use the elements you borrow. 
Especially keep in mind that photos or artwork hanging on the walls of your sets 
and music on the soundtrack (even if you own the CD or MP3) may be copyrighted. 
You should not include copyrighted works such as these in your movie without 
authorization.</P>
<P>A few other things to keep in mind are:</P>
    <TABLE class=numberedList cellSpacing=2 cellPadding=2 border=0>
      <TBODY>
  <TR vAlign=top>
    <TD class=listNumber noWrap align=right>
      <P>1.</P></TD>
    <TD>
      <P>Just because a work does not include a copyright notice (e.g., © 2007 
              Medical Rehabilitation Specialists) does not mean the work is in the public 
              domain. Copyright notices are generally not required for works to 
              be protected by copyright. </P></TD></TR>
  <TR vAlign=top>
    <TD class=listNumber noWrap align=right>
      <P>2.</P></TD>
    <TD>
      <P>Just because a work is easily available on the internet or elsewhere 
      does not mean you may use the work freely. Look for terms of use, such as 
      Creative Commons, that explain how works you find on the Internet may be 
      used. </P></TD></TR></TBODY></TABLE>
<P></P>
    <B>Public domain?</B> 
    <P></P>
<P>Just because a work is freely available, does not mean it is in the “public 
domain.” Copyright is for a limited term; it does not last forever. In the 
copyright context, “public domain” means the copyright term has expired. Once a 
work is in the public domain, it may be freely used without permission from the 
copyright owner.</P>
<P>Determining the term of copyright can be complex, particularly because 
copyright laws vary from country to country. Also, even if the copyright on a 
work has expired, you should be careful about how you use a public domain work. 
For example, a book may be in the public domain, but it might not be ok to scan 
the book cover to cover and post it on the internet. This is because the 
particular version of the book may contain new copyrightable material that is 
not in the public domain, such as cover art or footnotes.</P>
<P></P><B>Fair use?</B>
<P></P>
<P>In limited situations, you can use copyrighted works without permission from 
the copyright holder. It can be difficult to figure out whether use of 
copyrighted works without permission is legal, though, because the laws in this 
area are often vague and vary from country to country.</P>
<P>The copyright law in the United States has a doctrine called “fair use”. Fair 
use provides a defense to copyright infringement in some circumstances. For 
example, fair use allows documentary filmmakers to use very short clips of 
copyrighted movies, music and news footage without permission from the copyright 
owner. Fair use is a difficult concept because determining whether something is 
a fair use involves weighing four factors. Unfortunately, weighing the fair use 
factors rarely results in a clear-cut answer.</P>
<P>Rather than applying a fair use test, many other countries have specific 
exceptions to copyright infringement. The number and type of exceptions vary by 
country, but they frequently allow copyrighted materials to be used without 
permission from the copyright holder for activities such as nonprofit research, 
teaching, news reporting, or private study.</P>
<P>If you incorrectly decide that something is a fair use or falls into an 
exception to copyright infringement, you could be held criminally and civilly 
liable and have to pay damages. We suggest you talk to a lawyer if you have 
questions regarding fair uses of copyrighted works.</P>
<P></P><B>What happens if you upload copyrighted materials to one of our 
websites without permission?</B>
<P></P>
<P>By law, we are required to take down videos, music, photographs or other 
content you upload onto a website hosted by Medical Rehabilitation Specialists if we learn that it 
infringes someone else’s copyright. If you believe that we have mistakenly taken 
down content you uploaded that you own or have permission to upload, you can 
also let us know that. Finally, if you upload infringing content repeatedly, we 
will terminate your account and you could face criminal and civil penalties. So 
please, respect other people’s copyrights.</P>
<P></P><B>What if my stuff is on a Medical Rehabilitation Specialists website without my permission?</B>
<P></P>
    <P>If you believe that anything on a website hosted by Medical Rehabilitation Specialists infringes your copyright, let us know. Just provide us with the information 
      requested and we will see that your copyrighted works are taken down.</P>
<P></P>
    <P>Any rights not expressly granted herein are reserved
    </P>


            © {{currentYear}} Medical Rehabilitation Specialists. All rights reserved.

                <!-- END: TERMS -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  }
}
</script>

<style lang="scss" scoped>

</style>